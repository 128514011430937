html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    /* 1 */
    vertical-align: baseline;
    /* 2 */;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

a {
    background: transparent;
}

a:active,
a:hover {
    outline: 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

mark {
    background: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    /* 2 */
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

/* [ extend of normalize ] */
*,
*::before,
*::after {
    box-sizing: border-box;
}

:focus {
    outline: none;
}

#Debug {
    display: none;
}

html,
body {
    min-width: 320px;
}

body {
    background: #F2F4F7;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

hr,
button {
    text-align: left;
    border: none;
    background: none;
    padding: 0;
}

button {
    padding: 0;
}

.app-content {
    min-height: calc(100vh - (60px + 80px + 2px));
}

.app-content.dashboard-content:lang(en), .app-content.dashboard-content:lang(gb) {
   min-height: calc(100vh - (174px + 80px + 95px));
}

.app-content.dashboard-content {
     min-height: calc(100vh - (184px + 80px + 95px));
}

.app-content.editor-content {
     display: flex;
     min-height: auto;
     height: calc(100vh - 2px);
 }

.relative {
    position: relative;
}

.right {
    float: right !important;
}

.left {
    float: left !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.inline {
    display: inline-block;
}

.clear::before, .clear::after {
    content: ' ';
    display: table;
}

.clear::after {
    clear: both;
}

.text--overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-size-adjust: 100%;
}

body {
    font-family: 'Roboto', Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: .018em;
    font-size: 100%;
    color: #000;
}

a, .app-link {
    color: #00BAFF;
    text-decoration: none;
}

a:hover, a:focus, .app-link:hover, .app-link::focus {
  text-decoration: underline;
  color: #00BAFF;
}

a[href], .app-link {
    cursor: pointer;
}

p {
    font-weight: 400;
}

em {
    font-style: normal;
}

strong {
    font-weight: 700;
}

.preloading svg {
    display: none;
}

#preloader {
    position: fixed;
    z-index: 9999999999999999;
    background-color: #FFF;
    color: #000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#preloader.hidden {
    display: none;
}

#preloader.invisible {
    visibility: hidden;
}

#preloader svg {
    fill: #00BAFF;
}

.app-loading-placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
}

.app-loading-placeholder .loader-text {
      font-family: 'Roboto', Helvetica, sans-serif;
      font-weight: 300;
      width: 100%;
      height: 40px;
      position: absolute;
      top: -120px;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      font-size: 24px;
      color: #7990A1;
      margin: auto;
      padding: 0 30px;
}

.app-loading-placeholder .loader::before, .app-loading-placeholder .loader::after, .app-loading-placeholder .loader {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation: load 1.8s infinite ease-in-out;
  animation-fill-mode: both;
}

.app-loading-placeholder .loader {
      color: #E2E2E2;
      font-size: 20px;
      margin: auto;
      position: absolute;
      top: -120px;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateZ(0);
      animation-delay: -0.16s;
}

.app-loading-placeholder .loader::before {
      left: -3.5em;
      animation-delay: -0.32s;
}

.app-loading-placeholder .loader::after {
    left: 3.5em;
}

.app-loading-placeholder .loader::before, .app-loading-placeholder .loader::after {
     content: '';
     position: absolute;
     top: 0;
}

@keyframes load {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

.page-loader__wrapper {
    position: fixed;
    display: flex;
    z-index: 1;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.420);
}

.page-loader__wrapper--hidden {
    display: none;
}

.page-loader__icon {
    width: 32px;
    height: 32px;
    margin: auto;
    animation: rotate .69s linear infinite;
}

.page-loader__icon svg {
      fill: #00BAFF;
}

.font-loader {
    position: absolute;
    top: -100px;
    font-family: source-sans-basic, "Source Sans Pro", sans-serif;
    font-size: 1px;
    color: transparent;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-900 {
    font-weight: 900;
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
